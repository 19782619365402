export function Logo(props) {
  return (
    <svg className="h-5 w-auto fill-slate-200/70" width="115" height="115" viewBox="0 0 91 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8 48.31L24.44 52.31V44.8L35.81 41.33L59.28 49.33L24.44 59.96L0.970001 51.96L12.8 48.31ZM24.44 90.92L0.970001 82.92L12.77 79.32L24.44 83.32V75.79L67.16 62.79L90.63 70.79L24.44 90.92ZM24.44 28.92L0.970001 20.92L67.16 0.720001L90.63 8.72L24.44 28.92Z" className="fill-slate-200/30"/>
      <path d="M24.44 114.32L90.63 94.12V70.72L24.44 90.92V114.32Z" />
      <path d="M59.28 49.26L24.44 59.89V83.28L59.28 72.65V49.26Z" />
      <path d="M24.44 52.25L90.63 32.05V8.66L24.44 28.86V52.25Z" />
    </svg>
  )
}
