import Link from "next/link"

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  main: [
    { name: 'About', href: '/about/us' },
    { name: 'Terms', href: '/about/terms' },
    { name: 'Privacy', href: '/about/privacy-policy' },
    { name: 'Cookie', href: '/about/cookie-policy' },
    { name: 'Contact', href: 'mailto:info@errordeck.com' },
  ],
}

// get the current year
const currentYear = new Date().getFullYear()

export default function Footer() {
  return (
    <footer>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                href={item.href}
                className="text-base text-slate-500 hover:text-slate-900 dark:hover:text-slate-200"
                legacyBehavior>

                {item.name}

              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">&copy; {currentYear} Errordeck. All rights reserved.</p>
      </div>
    </footer>
  );
}