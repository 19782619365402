/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useSession } from "next-auth/react"
import {
  ChartBarIcon,
  DocumentChartBarIcon as DocumentReportIcon,
  Bars3Icon as MenuIcon,
  ArrowPathIcon as RefreshIcon,
  Squares2X2Icon as ViewGridIcon,
  XMarkIcon as XIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'
import { Logo } from './Logo'
import Button from './Button'

const resources = [
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '/security' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LandingPageMenu() {
  const { data: session } = useSession({
    required: false
  })
  return (
    <Popover className="relative">
      <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link href="/">

            <Logo className="h-10 w-auto fill-white" />

          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-slate-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-10">
          <Link
            href="/#features"
            className="text-base font-medium text-slate-300 hover:text-slate-400">
            
              Features
            
          </Link>
          
          <Link
            href="/pricing"
            className="text-base font-medium text-slate-300 hover:text-slate-400">
            
              Pricing
            
          </Link>
          <Link
            href="https://docs.errordeck.com"
            className="text-base font-medium text-slate-300 hover:text-slate-400">
          
            Docs
          
          </Link>

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    'group rounded-md inline-flex items-center text-base font-medium text-slate-300 hover:text-slate-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span>More</span>
                  <ChevronDownIcon
                    className={classNames(
                      'ml-2 h-5 w-5 group-hover:text-slate-400 text-slate-300 hover:text-slate-400'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-slate-800 px-5 py-6 sm:gap-8 sm:p-8">
                        {resources.map((resource) => (
                          <a
                            key={resource.name}
                            href={resource.href}
                            className="-m-3 p-3 block rounded-md hover:bg-slate-500"
                          >
                            <p className="text-base font-medium text-slate-100">{resource.name}</p>
                            <p className="mt-1 text-sm text-slate-300">{resource.description}</p>
                          </a>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          {session ? (
            <Button color="slate" className="ml-4" href="/dashboard">
              Dashboard
            </Button>
          ) : (
            <>
              <Link
                href="/sign_in"
                className="whitespace-nowrap text-base font-medium text-slate-300 hover:text-slate-400">
                
                  Sign in
                
              </Link>
              <Button color="blue" className="ml-4" href="/sign_up">
                Sign up
              </Button>
            </>
          )}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 z-10 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Logo className="h-10 w-auto fill-slate-800" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                <Link
                  href="/pricing"
                  className="text-base font-medium text-gray-900 hover:text-gray-700">
                  
                    Pricing
                  
                </Link>
                <Link
                  href="/docs"
                  className="text-base font-medium text-gray-900 hover:text-gray-700">
                  
                    Docs
                  
                </Link>

                {resources.map((resource) => (
                  <a
                    key={resource.name}
                    href={resource.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {resource.name}
                  </a>
                ))}
              </div>
              <div className="mt-6">
                <Link
                  href="/sign_up"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                  
                    Sign up
                  
                </Link>

                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <Link href="/sign_in" className="text-indigo-600 hover:text-indigo-500">
                    
                      Sign in
                    
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}